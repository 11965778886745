import { Link } from 'gatsby'
import React, { CSSProperties, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

export default function NotFoundPage() {
  const [isMount, setMount] = useState(false)

  useEffect(() => {
    setMount(true)
  }, [])

  if (!isMount) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <main style={styles.root}>
        <title>Not found</title>
        <h1 style={styles.heading}>Page not found</h1>
        <p style={styles.paragraph}>
          Sorry{' '}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              <br />
              Try creating a page in <code style={styles.code}>src/pages/</code>
              .
              <br />
            </>
          ) : null}
          <br />
          <Link to="/">Go home</Link>.
        </p>
      </main>
    </>
  )
}

const styles: Record<string, CSSProperties> = {
  wrapperLoader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100dvw',
    height: '100dvh',
    display: 'grid',
    placeItems: 'center',
  },
  root: {
    color: '#232129',
    padding: '96px',
    fontFamily: '-apple-system, Roboto, sans-serif, serif',
  },
  heading: {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
  },
  paragraph: {
    marginBottom: 48,
  },
  code: {
    color: '#8A6534',
    padding: 4,
    backgroundColor: '#FFF4DB',
    fontSize: '1.25rem',
    borderRadius: 4,
  },
}
